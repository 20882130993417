import { NextSeo } from 'next-seo';
import { FC } from 'react';
import { route } from '~src/utils/routes';
import { DEFAULT_SITE_TITLE } from '../../constants';

export const GradientPageSeo: FC<{
  title: string;
  description?: string;
}> = ({ title, description }) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        site_name: DEFAULT_SITE_TITLE,
        images: [
          {
            url: route({ pathname: '/api/og/gradient', query: { title } }),
            width: 1200,
            height: 630,
            alt: title,
          },
        ],
      }}
    />
  );
};

export const BlogPageSeo: FC<{
  title: string;
  description?: string;
  assetFolder: string;
}> = ({ title, description, assetFolder }) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        site_name: DEFAULT_SITE_TITLE,
        images: [
          {
            url: route({
              pathname: '/api/og/blog',
              query: { title, description, assetFolder },
            }),
            width: 1200,
            height: 630,
            alt: title,
          },
        ],
      }}
    />
  );
};

export const PageSeo: FC<{
  title: string;
  imgPath: string;
  description?: string;
}> = ({ imgPath, title, description }) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        site_name: DEFAULT_SITE_TITLE,
        images: [
          {
            url: route({ pathname: '/api/og', query: { imgPath, title } }),
            width: 1200,
            height: 630,
            alt: title,
          },
        ],
      }}
    />
  );
};

// export const CollectionPageSeo: FC<{
//   title: string;
//   address: string;
//   name: string | null;
//   totalNftsOwned: number | null;
//   description?: string;
// }> = ({ title, description, address, name, totalNftsOwned }) => {
//   return (
//     <NextSeo
//       title={title}
//       description={description}
//       openGraph={{
//         title,
//         description,
//         site_name: DEFAULT_SITE_TITLE,
//         images: [
//           {
//             url: route({ pathname: '/api/og/collection', query: { address, name, totalNftsOwned } }),
//             width: 1200,
//             height: 630,
//             alt: title,
//           },
//         ],
//       }}
//     />
//   );
// };

export const NftPageSeo: FC<{
  title: string;
  name: string;
  projectName: string;
  description?: string;
}> = ({ title, description, name, projectName }) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        site_name: DEFAULT_SITE_TITLE,
        images: [
          {
            url: route({
              pathname: '/api/og/nft',
              query: { name, projectName },
            }),
            width: 1200,
            height: 630,
            alt: title,
          },
        ],
      }}
    />
  );
};
