import { FC, useCallback, useState } from 'react';
import { ActionA } from '~src/components/anchor';
import { InheritBalancer } from '~src/components/balancer';
import { ActionButton, ButtonRow } from '~src/components/button';
import { ContentQuoteFooter } from '~src/components/footer/contentQuoteFooter';
import { DefaultPageLayout, WidthContainer } from '~src/components/layout';
import { GradientPageSeo } from '~src/components/seo';
import { H2, P } from '~src/components/text';
import { HighlightSpan } from '~src/components/text/spans';
import { ContentSpacer } from '~src/styled-system/jsx';
import { route } from '~src/utils/routes';

const GOOD_LUCK_CHANCE = 0.001;

export const NotFound: FC = () => {
  const [bonkCounter, setBonkCounter] = useState(0);
  const [isGoodLuck, setIsGoodLuck] = useState(false);
  const handleBonkForGoodLink = useCallback(() => {
    setBonkCounter((b) => b + 1);
    if (Math.random() < GOOD_LUCK_CHANCE * (bonkCounter / 200)) {
      setIsGoodLuck(true);
    }
  }, [bonkCounter]);

  return (
    <>
      <GradientPageSeo title={'404'} />
      <DefaultPageLayout>
        <WidthContainer
          width={'xs'}
          height={`[calc(100vh - token(sizes.nav) - 100px)]`}
          padMobile
          flx={'flexColumnCenter'}
        >
          <ContentSpacer size={'fourExLargestBox'} />
          <H2 textAlign={'center'} color={'gray.300'} isMobile>
            <InheritBalancer>Oop! Looks like you are lost.</InheritBalancer>
          </H2>
          <ContentSpacer size={'box'} />
          <ButtonRow gap={'innerBox'}>
            <ActionA href={route({ pathname: '/' })} btnType={'quaternary'}>
              Return to home
            </ActionA>
            <ActionButton
              onClick={handleBonkForGoodLink}
              btnType={'quaternarySolid'}
            >
              Bonk for good luck
            </ActionButton>
          </ButtonRow>
          {isGoodLuck ? (
            <>
              <ContentSpacer size={'box'} />
              <P
                textStyle={'p.display.multi'}
                color={'gray.600'}
                textAlign={'center'}
              >
                Woah! Good luck just happened! DM @prrfbeauty with the message{' '}
                <br />{' '}
                <HighlightSpan color={'gray.200'}>
                  'A bonk a day keeps the doctor away!'
                </HighlightSpan>{' '}
                and your best{' '}
                <HighlightSpan color={'gray.200'}>
                  knock-knock joke!
                </HighlightSpan>
                .
              </P>
            </>
          ) : (
            <>
              <ContentSpacer size={'box'} />
              {bonkCounter !== 0 && (
                <P color={'gray.300'}>Bonk: {bonkCounter}</P>
              )}
            </>
          )}
          <ContentSpacer size={'fourExLargestBox'} />
        </WidthContainer>
        <ContentQuoteFooter>
          {'"Not all those who wander are lost." - J. R. R. Tolkien'}
        </ContentQuoteFooter>
      </DefaultPageLayout>
    </>
  );
};
