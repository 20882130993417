import { FC } from 'react';
import { ContentSpacer, Flex } from '~src/styled-system/jsx';
import { InheritBalancer } from '../balancer';
import { WidthContainer } from '../layout';
import { P } from '../text';

export const ContentQuoteFooter: FC<{ children: string }> = ({ children }) => {
  return (
    <>
      {' '}
      {/* <ContentSpacer 
      spacing={'twoExLargestBox'} /> */}
      <ContentSpacer size={'twoExLargestBox'} isMobile />
      <WidthContainer width={'xs'}>
        <Flex direction={'column'} align={'center'}>
          <P
            textStyle={'p.display.multi'}
            color={'gray.400'}
            textAlign={'center'}
          >
            <InheritBalancer>{children}</InheritBalancer>
          </P>
        </Flex>
      </WidthContainer>
      <ContentSpacer size={'twoExLargestBox'} isMobile />
      {/* <ContentSpacer spacing={'twoExLargestBox'} isMobile /> */}
    </>
  );
};
